<template>
    <div class="widget-container">
        <ul class="messages-item">
            <Message v-for="message in $messages.messages" v-bind:key="message.id" :message="message" />
        </ul>
    </div>
</template>
<script>
import Message from '@/components/dashboard/message.vue';

export default {
    components: { Message },

    watch: {
        "$firebase.account"() {
            this.Start();
        },
        "$donatrix.settingsReady"() {
            this.Start();
        },
    },

    created() {
        this.Start();
    },

    methods: {
        Start() {
            if (!this.$donatrix.settingsReady || !this.$firebase.account) return;
            this.$messages.StartListen();
        },
    }
}
</script>
<style lang="scss" scoped>
.widget-container {
    height: 100%;
    background: #432D64;
}
.messages-item {
    max-height: max-content;
    padding: 14px;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #432D64;
}

.messages-item::-webkit-scrollbar {
    width: 9px;
    background-color: transparent;
}

.messages-item::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.20);
}
</style>